.richTextEditor ul {
  padding-left: 30px !important;
}
.richTextEditor ol {
  padding-left: 30px !important;
}
filter {
  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: white;

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}